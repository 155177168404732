.main-form {
  form {
    background-color: var(--white-color);
    border-radius: 10px;
    padding: 20px;
  }

  .image-uploader {
    display: flex;
    flex-direction: column;
    margin: 10px;
  }

  .image-uploader label {
    margin-bottom: 5px;
    font-weight: bold;
  }

  .image-uploader .required {
    color: red;
  }

  .file-input {
    display: flex;
    align-items: center;
    // padding:  3px;
    border-radius: 6px;
  }

  .file-input input[type="text"] {
    flex-grow: 1;
    padding: 5px;
    border: none;
    border-radius: 6px;
    border: 1px solid #a7b3bf;
    color: var(--black-color);
    opacity: 0.65;
    height: 33px;
    background-color: var(--white-color);
  }

  .choose-file-button {
    background-color: var(--primary-color);
    color: white;
    padding: 8px 20px;
    border-radius: 6px;
    cursor: pointer;
    text-align: center;
    opacity: inherit;
    height: 33px;
    white-space: nowrap;
    display: flex;
    align-items: center;
  }

  .choose-file-button:hover {
    background-color: var(--primary-color);
  }

  .form-heading {
    border-bottom: 1.8px solid #ced4da;
    margin: 10px 10px 30px 10px;

    h5 {
      color: var(--primary-color);
      text-transform: capitalize;
      font-weight: 600;
    }
  }

  label {
    color: var(--black-color);
    opacity: 0.65;
    font-weight: 500;
    font-size: 14px;
  }

  .radio-inner {
    display: flex;
    gap: 30px;
    margin-top: 10px;
  }

  .p-radiobutton .p-radiobutton-box {
    border-radius: 16%;
  }

  .colored-row {
    background-color: rgb(128 128 128 / 8%);
    border-radius: 6px;
    padding: 20px 15px;
    margin-bottom: 1rem;
    margin-top: 1rem !important;
  }

  .buttons {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin: 30px;

    .p-button {
      align-items: center;
      justify-content: center;
      border-radius: 6px;
    }
  }

  .p-button {
    border-radius: 6px;
    margin-right: 0 !important;
  }

  .field {
    margin-bottom: 0.5rem;
  }

  .p-inputtext {
    padding: 7px;
    height: 33px;
  }

  .pi-times-circle {
    font-size: 20px !important;
  }

  .choose-image-none {
    display: none;
  }

  .map-sports {
    gap: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 525px) {
      display: block;
    }

    .map-sport-container {
      border: 1px solid #dfdcdc82;
      margin: 10px;
      border-radius: 10px;
      padding: 10px;
      box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02),
        0px 0px 2px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.08);
    }

    .remove-button {
      display: flex;
      align-items: center;
      img{
        width: 25px;
      }

     
    }

    .add-button {
        height: 2rem;
        .pi {
          font-weight: 600;
        }
    }
  }

  .field.grid,
  .formgrid.grid {
    margin: 10px;
  }
}

.tab-buttons {
  display: flex;
  gap: 22px;

  .p-button {
    background-color: #8fcfcc38;
    color: var(--black-color);
    border-radius: 6px;
    border: none;
    padding: 15px 60px;
    border: 2px solid #d9dfdf54;
    font-weight: 500;
    font-size: 15px;

    &:hover {
      border: none !important;
      background-color: #8fcfcc38;
      color: var(--black-color) !important;
      border: 2px solid #d9dfdf54 !important;
    }
  }
  .active-tab {
    border: 2px solid var(--primary-color);
    font-weight: 600;

    &:hover {
      border: 2px solid var(--primary-color) !important;
    }
  }
}

.p-tag {
  font-size: 0.9rem;
}

.p-dialog {
  label {
    color: var(--black-color);
  }

  .p-dialog.p-confirm-dialog .p-confirm-dialog-icon {
    font-size: 4rem !important;
  }

  .p-dialog-content {
    display: grid;
    place-items: center;
    gap: 20px;
    padding-top: 0 !important;
    padding: 0 1.5rem 1rem 1.5rem !important;
  }

  .p-confirm-dialog-icon {
    font-size: 3.5rem !important;
  }

  .p-inputtextarea {
    margin-top: 0.5rem;
    width: 100%;
  }
}

.p-dialog .p-dialog-footer {
  justify-content: center;
}

.dialog-footer {
  gap: 10px;
}

.secondary-button {
  border-radius: 6px;
  border: 1px solid var(--primary-color);
  background-color: var(--white-color);
  color: var(--primary-color);

  &:hover {
    background-color: #f1d3d1 !important;
    color: var(--primary-color) !important;
  }
}

.error-indicator {
  color: red;
}
