.detailed-view {
  display: flex;
  gap: 20px;

  @media (max-width: 768px) {
    display: block;
  }
}

.courts-map {
  border: 2px solid rgb(139 110 110 / 15%);
  padding: 20px;
  border-radius: 6px;
  background-color: var(--white-color);
  border-radius: 10px;
  text-transform: capitalize;
  width: 100%;
  .name {
    display: flex;
    align-items: center;
    h5 {
      margin-bottom: 0px;
      margin-right: 10px;
    }

    p {
      font-size: 16px;
      text-transform: capitalize;
    }
  }

  .slot-details {
    .inner {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      font-size: 16px;

      h6 {
        margin-bottom: 0;
        margin-right: 10px;
        font-size: 16px;
        font-weight: 600;
      }
      p {
        color: var(--black-color);
      }
    }
  }

  .slots-container {
    display: flex;
    width: 100%;
    gap: 16px;

    @media (max-width: 768px) {
      display: grid;
    }
  }

  .cardslote {
    background-color: var(--surface-card);
    border-radius: 6px;
    box-shadow: 0px 3px 5px rgba(66, 64, 64, 0.02),
      0px 0px 2px rgba(0, 0, 0, 0.05), 0px 1px 4px rgb(167 136 136 / 48%) !important;
    padding: 20px;
    background-color: var(--light-color);
  }
}

.viewdetails {
  border: 2px solid rgb(139 110 110 / 15%);
  padding: 20px;
  border-radius: 6px;
  background-color: var(--white-color);
  border-radius: 10px;
  text-transform: capitalize;
  margin-bottom: 20px;
  width: 100%;
}

.inner {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-size: 16px;

  h6 {
    margin-bottom: 0;
    margin-right: 10px;
    font-size: 16px;
    font-weight: 600;
  }
  p {
    color: var(--black-color);
  }

  .p-image-preview-container > img {
    width: 200px;
    height: 80px;
    border-radius: 6px;
    border: 2px solid var(--primary-color);
    object-fit: cover;
    box-shadow: 0px 3px 5px rgba(66, 64, 64, 0.02),
      0px 0px 2px rgba(0, 0, 0, 0.05), 0px 1px 4px rgb(167 136 136 / 48%) !important;

    @media (max-width: 768px) {
      width: 50px;
    }
  }
}

.courts-view {
  // border: 2px solid rgb(139 110 110 / 15%);
  // padding: 20px;
  // border-radius: 6px;
  // background-color: var(--white-color);
}
